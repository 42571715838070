import React from "react"
import styled from "styled-components"
import TopBackground from "../images/onepager-background.png"
import Logo from "../images/logo-new2.png"
import MountainBackground from "../images/onepager-mountains.png"
import PolarBackground from "../images/polar-background.png"
import Globe from "../images/globe.png"
import Question from "../images/question.png"
import Checkmark from "../images/checkmark.png"
import { Link } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import TeamPhoto from "../images/team-photo.png"

const CustomDiv = styled.div`
  min-width: 0;
  text-align: center;
`

const TopContainer = styled(CustomDiv)`
  background-image: url(${TopBackground});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 8em 2em 4em 2em;
  color: white;
  @media (min-width: 768px) {
    display: grid;
    padding: 6em 0 4em 0;
    grid-template-columns: 1fr 4fr 1fr;
  }
  @media (min-width: 1440px) {
    padding: 12em 0 10em 0;
  }
`

const DefaultContainer = styled(CustomDiv)`
  background-color: #f6f6f6;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 6em 1em 6em 1em;
  @media (min-width: 425px) {
    padding: 10em 2em 10em 2em;
  }
  @media (min-width: 1024px) {
    padding: 10em 14em 10em 14em;
    display: block;
  }
  @media (min-width: 1440px) {
    padding: 10em 20em 10em 20em;
  }
  @media (min-width: 2000px) {
    padding: 10em 40em 10em 40em;
  }
`

const PolarContainer = styled(CustomDiv)`
  background-image: url(${PolarBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

const PolarSubContainer = styled(CustomDiv)`
  background-color: #030d2299;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 6em 1em 4em 1em;
  @media (min-width: 425px) {
    padding: 10em 2em 10em 2em;
  }
  @media (min-width: 768px) {
    padding: 6em 14em 6em 14em;

    display: block;
  }
  @media (min-width: 1440px) {
    padding: 12em 20em 10em 20em;
  }
  @media (min-width: 2000px) {
    padding: 12em 40em 10em 40em;
  }
`

const MountainsContainer = styled(CustomDiv)`
  background-image: url(${MountainBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  color: white;
  display: flex;
  flex-flow: row wrap;
  padding 5em 2em 5em 2em;
  justify-content: center;
  @media (min-width: 768px){
      display: block;
      padding: 7.5em 14em 10em 14em;
      text-align: center;
  }
  @media(min-width: 1440px){
      padding: 15em 0 20em 0;
  }
`

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.2em;
  margin-top: 2em;
  flex-flow: row wrap;
  @media (min-width: 1440px) {
    gap: 3em;
  }
`

const StepsBox = styled.div`
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding: 2em;
  width: 20em;
  margin-bottom: 1em;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`

const HowContainer = styled(CustomDiv)`
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 238, 255, 1) 100%
  );
`

const TeamContainer = styled(CustomDiv)`
  display: flex;
  flex-flow: row wrap;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const OnePager = () => {
  const { isLoggedIn } = useAuth()

  return (
    <div>
      <a href="/">
        <button
          style={{
            backgroundColor: "#05b063",
            color: "white",
            borderRadius: "0.5rem",
            padding: "0.5rem 1rem",
            margin: "2rem",
            position: "absolute",
          }}
        >
          Back
        </button>
      </a>
      <TopContainer>
        <div />
        <div style={{ marginBottom: "1rem" }}>
          <a href="/" style={{ textDecoration: "none" }}>
            <img
              src={Logo}
              alt="Logo"
              style={{
                marginBottom: "0",
                height: "42px",
                width: "42px",
                alt: "logo",
                verticalAlign: "middle",
                display: "inline",
              }}
            />
            <h4
              style={{
                display: "inline",
                verticalAlign: "middle",
                fontSize: "1.5rem",

                color: "white",
              }}
            >
              <b> YOU CHANGE EARTH</b>
            </h4>
          </a>
        </div>
        <div />
        <div />
        <div>
          <h1 style={{ fontSize: "2rem" }}>Change Earth for the better</h1>
        </div>
        <div />
        <div />
        <div
          style={{ marginBottom: "4em", marginTop: "1.5em", lineHeight: "1.4" }}
        >
          <p>
            Our mission is to help everyone find a role in building a
            climate-safe world. Get started to make your personalized climate
            action plan today!
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoggedIn ? (
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <div
                  className="button homepageButton"
                  style={{
                    width: "12rem",
                    marginTop: "1rem",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 700,
                      textDecoration: "none",
                    }}
                  >
                    Return to Dashboard
                  </p>
                </div>
              </Link>
            ) : (
              <Link to="/mainform" style={{ textDecoration: "none" }}>
                <div
                  className="button homepageButton"
                  style={{
                    width: "12rem",
                    marginTop: "1rem",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 700,
                      textDecoration: "none",
                    }}
                  >
                    GET STARTED
                  </p>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div />
      </TopContainer>
      <DefaultContainer>
        <h5 style={{ fontSize: "1.75rem", marginTop: "-2rem" }}>OUR MISSION</h5>
        <br />
        <p style={{ marginTop: "1rem" }}>
          Our mission is to help everyone find a role in building a climate-safe
          world. You Change Earth is a smart online tool to recommend
          personalized actions people can take to reduce their carbon footprint
          and connect with others to reduce climate pollution. You Change Earth
          empowers people and gives them the solutions, information and support
          to act effectively in tackling climate change.
        </p>
      </DefaultContainer>
      <PolarContainer>
        <PolarSubContainer>
          <h2 style={{ fontSize: "1.75rem" }}>PROBLEM</h2>
          <br />
          <p
            style={{
              fontSize: "1.25rem",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            The climate crisis is worsening and if we don’t significantly reduce
            emissions within the next ten years we risk increasing Earth’s
            temperature above the 1.5 degrees tipping point which risks
            catastrophic runaway climate change.
          </p>
          <p
            style={{
              fontSize: "1.25rem",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            Climate change is already bringing more extreme weather conditions
            and billions in costs but runaway climate change risks our very
            survival.
          </p>
          <p>
            <a
              style={{
                color: "var(--green)",
                textDecoration: "none",
              }}
              href="https://350.org/science/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>Why does climate change matter?</b>
            </a>
          </p>
        </PolarSubContainer>
      </PolarContainer>
      <HowContainer>
        <DefaultContainer style={{ backgroundColor: "transparent" }}>
          <h2 style={{ fontSize: "1.75rem" }}>SOLUTION</h2>
          <br />
          <p style={{ fontSize: "1.2rem", marginTop: "2rem" }}>
            There is still time to avert a climate catastrophe if we all work
            together using our words and actions.
          </p>
          <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
            We live in an interconnected world where individuals and companies
            both need to reduce the emissions which are causing the climate
            crisis.
          </p>
          <p style={{ fontSize: "1.2rem", marginTop: "1rem" }}>
            You Change Earth recommends actions that individuals can take to
            reduce their personal carbon footprint as well as using their voice
            to see positive corporate and political change. We connect people
            with groups campaigning for climate action.
          </p>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoggedIn ? (
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <div
                  className="button homepageButton"
                  style={{
                    width: "12rem",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                    marginTop: "1rem",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 700,
                      textDecoration: "none",
                    }}
                  >
                    Return to Dashboard
                  </p>
                </div>
              </Link>
            ) : (
              <Link to="/mainform" style={{ textDecoration: "none" }}>
                <div
                  className="button homepageButton"
                  style={{
                    width: "12rem",
                    color: "white",
                    textAlign: "center",
                    textDecoration: "none",
                    marginTop: "1rem",
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      fontWeight: 700,
                      textDecoration: "none",
                    }}
                  >
                    Check it out here
                  </p>
                </div>
              </Link>
            )}
          </div>
        </DefaultContainer>
        <h2 style={{ fontSize: "1.75rem" }}>HOW IT WORKS</h2>
        <StepsContainer>
          <StepsBox>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#e9eef9",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={Question}
                alt="Question"
                style={{
                  height: "50px",
                }}
              />
            </div>
            <br />
            <h4 style={{ fontSize: "1.4rem" }}>Quick questions</h4>
            <p style={{ marginTop: "1rem" }}>
              Answer a few quick questions and select the meaningful climate
              projects that suit your interests.
            </p>
          </StepsBox>
          <StepsBox>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#e9eef9",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={Checkmark}
                alt="Checkmark"
                style={{ height: "61px", marginTop: "10px" }}
              />
            </div>
            <br />
            <h4 style={{ fontSize: "1.4rem" }}>Complete your actions</h4>
            <p style={{ marginTop: "1rem" }}>
              Complete the steps on your actions and see your progress on your
              dashboard!
            </p>
          </StepsBox>
          <StepsBox>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#e9eef9",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img src={Globe} alt="globe" style={{ height: "50px" }} />
            </div>
            <br />
            <h4 style={{ fontSize: "1.4rem" }}>Join communities</h4>
            <p style={{ marginTop: "1em" }}>
              You’ve changed Earth. We then show you great communities and ways
              use your voice over our critical next ten years.
            </p>
          </StepsBox>
        </StepsContainer>
        <br />
        <br />
        <br />
      </HowContainer>
      <TeamContainer>
        <div style={{ backgroundColor: "#d4e6c9" }}>
          <img src={TeamPhoto} alt="Team photo" style={{ padding: "2rem" }} />
        </div>
        <div
          style={{
            textAlign: "left",
            padding: "7rem 4rem",
            backgroundColor: "#e9eff9",
          }}
        >
          <h3 style={{ marginBottom: "2rem", fontSize: "1.5rem" }}>
            <b>Who we are</b>
          </h3>
          <p style={{ marginBottom: "2rem" }}>
            We are a team of young environmentalists who have spent 10 months
            building the ultimate individual guide to climate change. Working
            together across seven countries and five continents, we are
            passionate about helping millions of caring people take action in
            preventing climate catastrophe.
          </p>
          <p>
            <b>We’re based all around the place!</b>
          </p>
        </div>
      </TeamContainer>
      <MountainsContainer>
        <h4 style={{ fontSize: "1.75rem", lineHeight: "1.2" }}>
          Ready to make a change? Start your personalized climate actions with
          You Change Earth
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoggedIn ? (
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "2rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  Return to Dashboard
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/mainform" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "2rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  GET STARTED
                </p>
              </div>
            </Link>
          )}
        </div>
        <br />
      </MountainsContainer>
    </div>
  )
}

export default OnePager

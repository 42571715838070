import React from "react"

import SEO from "../components/seo"
import OnePager from "../sections/one-pager"

const OnePagerPage = () => (
  <div>
    <SEO title="One pager" />
    <OnePager />
  </div>
)

export default OnePagerPage
